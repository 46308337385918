import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container/container";
import HeroSection from "../components/hero-section/hero-section";
import GraphQLErrorList from "../components/graphql-error-list";
import IconBox from "../components/icon-box/icon-box";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import BlockContent from "../components/block-content";
import loadable from "@loadable/component";
import scrollTo from "gatsby-plugin-smoothscroll";
import Icon from "../components/icon";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import CostPotentialCalculator from "../components/cost-potential-calculator/cost-potential-calculator";

const HubSpotFormComponent = loadable(() => import("../components/hubspot-form/hubspot-form"));

export const query = graphql`
  query GetStartedPageQuery {
    site: sanityGetStartedPage {
      subtitle
      title
      _rawDescription
      seo
      keywords
      id
      mainImage {
        asset {
          _id
        }
        alt
      }
    }
  }
`;

const GetStartedPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  const imageUrl = imageUrlFor(buildImageObj(site.mainImage))
    .width(600)
    .width(600)
    .fit("fill")
    .url();

  const solutionList: string[] = [
    "Reduser utestående kundefordringer",
    "Frigjør arbeidskapital og forbedre likviditeten​",
    "Optimalisere kunderisiko ved å avdekke faresignaler​",
    "Få konkursvarsler direkte fra domstolene",
    "Se kredittrisiko på egne kunder og ta grep tidlig for ​å unngå tap som følge av konkurser",
    "Totaloversikt over alt dine kunder skylder deg",
    "Automatiser oppfølging av sene betalere"
  ];

  console.log("getstarted site helmet", site);

  return (
    <Layout>
      <SEO title={site.title} description={site.seo} keywords={site.keywords} image={imageUrl} />
      <HeroSection>
        <div>
          {site._rawDescription && <BlockContent blocks={site._rawDescription || []} />}
          <button onClick={() => scrollTo("#get-started-form")} className="primary-link-btn">
            I am ready to book a demo
          </button>
        </div>
        <div
          data-sal="slide-up"
          data-sal-delay="350"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <Icon symbol="axeptiaXxplainedV2"></Icon>
        </div>
      </HeroSection>

      <Container backgroundColor="white" role="">
        <CostPotentialCalculator />
      </Container>

      <Container backgroundColor="gray" role="complementary">
        <div className="icon-container">
          <IconBox
            width={90}
            height={90}
            imageUrl={"/get-in-touch.png"}
            title="Get in touch"
            description="Send us an email to sales@axeptia.com or by using the form below"
          ></IconBox>
          <IconBox
            width={90}
            height={90}
            imageUrl={"/Lets-talk.png"}
            title="Let´s talk"
            description="We will contact you to figure out your needs and plan a date for a demo."
          ></IconBox>
          <IconBox
            width={90}
            height={90}
            imageUrl={"/demo.png"}
            title="Demo"
            description="We will invite you for a show and tell. Tell us about the pains you experience today and we will show you how Axeptia can start to mend them."
          ></IconBox>
          <IconBox
            width={90}
            height={90}
            imageUrl={"/star.png"}
            title="Try it our yourself"
            description="Try Axeptia 14 days for free, no strings attached. Although, be warned, you might get hooked."
          ></IconBox>
        </div>
      </Container>
      <Container backgroundColor="white" id="get-started-form" role="form">
        <HubSpotFormComponent
          portalId={5841642}
          formId="a4a09ee6-c635-439c-857d-cd8219a4debd"
          region="na1"
          title="Get in touch"
          text="Please leave your details and we will contact you as soon as possible. In addition to your contact info, feel free to describe your current needs, and will start solving those needs before meeting you."
        ></HubSpotFormComponent>
      </Container>
    </Layout>
  );
};
export default GetStartedPage;
