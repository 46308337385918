import React, { useState, useEffect } from "react";
import * as styles from "./cost-potential-calculator.module.scss";
import Icon from "../icon";

const CostPotentialCalculator: React.FC = () => {
  const [amountPaid, setAmountPaid] = useState<number>(1500000);
  const [daysOverdue, setDaysOverdue] = useState<number>(14);
  const [financingCost, setFinancingCost] = useState<number>(5);
  const [dailyCost, setDailyCost] = useState<number>(0);
  const [monthlyCost, setMonthlyCost] = useState<number>(0);
  const [proportionToFinance, setProportionToFinance] = useState<number>(50);
  const [amountFinanced, setAmountFinanced] = useState<number>(0);
  const [savingsMonthly, setSavingsMonthly] = useState<number>(0);
  const [axeptiaPrice, setAxeptiaPrice] = useState<number>(3490);
  const [monthlyCostSavings, setMonthlyCostSavings] = useState<number>(0);
  const [proportionPaidOnTime, setProportionPaidOnTime] = useState<number>(50);

  useEffect(() => {
    const calculatedDailyCost = ((financingCost / 100) * amountPaid) / 30;
    setDailyCost(parseFloat(calculatedDailyCost.toFixed(0)));
  }, [financingCost, amountPaid]);

  useEffect(() => {
    const calculatedMonthlyCost = dailyCost * daysOverdue;
    setMonthlyCost(parseFloat(calculatedMonthlyCost.toFixed(0)));
  }, [dailyCost, daysOverdue]);

  useEffect(() => {
    const calculatedAmountFinanced = (proportionToFinance / 100) * amountPaid;
    setAmountFinanced(parseFloat(calculatedAmountFinanced.toFixed(0)));
  }, [proportionToFinance, amountPaid]);

  useEffect(() => {
    const foo = (proportionToFinance / 100) * (proportionPaidOnTime / 100);
    const calculatedSavingsMonthly = foo * monthlyCost;
    setSavingsMonthly(parseFloat(calculatedSavingsMonthly.toFixed(0)));
  }, [proportionToFinance, proportionPaidOnTime, monthlyCost]);

  useEffect(() => {
    const calculatedMonthlyCostSavings = savingsMonthly - axeptiaPrice;
    setMonthlyCostSavings(parseFloat(calculatedMonthlyCostSavings.toFixed(0)));
  }, [savingsMonthly, axeptiaPrice]);

  const handleAmountPaidChange = value => {
    if (!isNaN(value)) {
      const number = Number(value); // converts '' from empty input -> 0
      setAmountPaid(number);
    }
  };

  const handleDaysOverdueChange = value => {
    if (!isNaN(value)) {
      const number = Number(value); // converts '' from empty input -> 0
      setDaysOverdue(number);
    }
  };

  const handleFinancingCostChange = value => {
    if (!isNaN(value)) {
      const number = Number(value); // converts '' from empty input -> 0
      setFinancingCost(number);
    }
  };

  const handleProportionToFinanceChange = value => {
    if (!isNaN(value)) {
      const number = Number(value); // converts '' from empty input -> 0
      setProportionToFinance(number);
    }
  };

  return (
    <div>
      <h2>Potential Cost Save Calculator</h2>

      <div className={styles.container}>
        <div>
          <div className={styles.inputObject}>
            <label className={styles.inputLabel} htmlFor="amountPaid">
              Amount Paid Overdue
            </label>
            <input
              className={styles.inputField}
              id="amountPaid"
              value={amountPaid}
              onChange={event => {
                handleAmountPaidChange(event.target.value);
              }}
            />
          </div>
          <div className={styles.inputObject}>
            <label className={styles.inputLabel} htmlFor="daysOverdue">
              Days Overdue
            </label>
            <input
              className={styles.inputField}
              id="daysOverdue"
              value={daysOverdue}
              onChange={event => {
                handleDaysOverdueChange(event.target.value);
              }}
            />
          </div>
          <div className={styles.inputObject}>
            <label className={styles.inputLabel} htmlFor="financingCost">
              Financing Cost (%)
            </label>
            <input
              className={styles.inputField}
              id="financingCost"
              value={financingCost}
              onChange={event => {
                handleFinancingCostChange(event.target.value);
              }}
            />
          </div>
          <div className={styles.inputObject}>
            <label className={styles.inputLabel} htmlFor="proportionToFinance">
              Proportion of overdue that must be financed (%)
            </label>
            <input
              className={styles.inputField}
              id="proportionToFinance"
              value={proportionToFinance}
              onChange={event => {
                handleProportionToFinanceChange(event.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div className={styles.outputObject}>
            <label>Daily Cost </label>
            <span>{dailyCost.toLocaleString()},-</span>
          </div>
          <div className={styles.outputObject}>
            <label>Monthly Cost </label>
            <span>{monthlyCost.toLocaleString()},-</span>
          </div>
          <div className={styles.outputObject}>
            <label>Sum of Overdue to be Financed </label>
            <span>{amountFinanced.toLocaleString()},-</span>
          </div>
          {/* <Divider variant="inset" /> */}
          <div className={styles.outputObject}>
            <label>Monthly Savings with Axeptia </label>
            <span>{savingsMonthly.toLocaleString()},-</span>
          </div>
          <div className={styles.outputObject}>
            <label>Axeptia Price (from) </label>
            <span>{axeptiaPrice.toLocaleString()},-</span>
          </div>
          <div className={styles.outputObjectLarge}>
            <label>Monthly Cost Savings </label>
            <span>{monthlyCostSavings.toLocaleString()},-</span>
            {/* If minus -> show per nestor */}
          </div>
        </div>

        <div className={styles.iconContainer}>
          <Icon symbol="customersIcon"></Icon>
        </div>
      </div>
    </div>
  );
};

export default CostPotentialCalculator;
